import React , { useState } from "react";  

import Currencies from "./components/Currency/Currencies";
import CurrenciesFilter from "./components/Currency/CurrenciesFilter";

// ---------------------------------------------------------

const currency_list = [
  {
    "code": "HRK",
    "conversionRates": [
      {
        "code": "HRK",
        "conversionRate": 1
      },
      {
        "code": "EUR",
        "conversionRate": 7.522476
      },
      {
        "code": "USD",
        "conversionRate": 7.54458
      },
      {
        "code": "RSD",
        "conversionRate": 0.06416
      },
    ]
  },
  {
    "code": "EUR",
    "conversionRates": [
      {
        "code": "HRK",
        "conversionRate": 0.13284
      },
      {
        "code": "EUR",
        "conversionRate": 1
      },
      {
        "code": "USD",
        "conversionRate": 1.0022
      },
      {
        "code": "RSD",
        "conversionRate": 0.00852
      },
    ]
  },
  {
    "code": "USD",
    "conversionRates": [
      {
        "code": "HRK",
        "conversionRate": 0.13255
      },
      {
        "code": "EUR",
        "conversionRate": 0.9978
      },
      {
        "code": "USD",
        "conversionRate": 1
      },
      {
        "code": "RSD",
        "conversionRate": 0.0085
      },
    ]
  },
  {
    "code": "RSD",
    "conversionRates": [
      {
        "code": "HRK",
        "conversionRate": 15.5862
      },
      {
        "code": "EUR",
        "conversionRate": 117.3326
      },
      {
        "code": "USD",
        "conversionRate": 117.5913
      },
      {
        "code": "RSD",
        "conversionRate": 1
      },
    ]
  },
]

// ---------------------------------------------------------



const App = () => {

  const [fromCurrency, setFromCurrency] = useState('');
  const [toCurrency, setToCurrency] = useState('');
  const [enteredAmount, setEnteredAmount] = useState(0);
  const [calculatedValue, setCalculatedValue] = useState(0);

  const filterCurrencies = (selectedCurrency) => {
    setCalculatedValue(0);
    setToCurrency(selectedCurrency);
  }

  const filteredCurrencies = currency_list.filter((currency) => {
      return (currency.code === toCurrency);
  });

  const checkResult = (enteredAmount, currency) => {

    if( (enteredAmount > 0 && currency.length === 3) ){

      const chosenCurrency = currency_list.filter((currency) => {
          return (currency.code === toCurrency);
      });

      const conversionRates = chosenCurrency[0]["conversionRates"];
      
      const chosenCurrencyRate = conversionRates.filter((conversionRate) => {
          return (conversionRate.code === currency);
      });

      let result = enteredAmount*(chosenCurrencyRate[0]["conversionRate"]);

      setFromCurrency(currency);
      setEnteredAmount(enteredAmount);
      setCalculatedValue(result.toFixed(2));

    }else{
      alert("Amount must be greater then zero, and you must choose currency for the amount ! ");
    }
  }
  

  return (
    <div>
      
      <h1 style={{color: "#89acc4", textAlign: "center"}}> Currency Converter </h1>

      <CurrenciesFilter 
        currencies={currency_list} 
        toCurrency={toCurrency} 
        onChangeCurrency={filterCurrencies}
      ></CurrenciesFilter>

      <Currencies  
        fromCurrency={fromCurrency} 
        enteredAmount={enteredAmount} 
        checkResult={checkResult} 
        toCurrency={toCurrency} 
        currencies={filteredCurrencies} 
        calculatedValue={calculatedValue} 
      ></Currencies>

    </div>
  );
}

export default App;
