import React, { useState } from "react";

import './CurrencyItem.css';
import Card from '../UI/Card';


const CurrencyItem = (props) => {

    const [enteredAmount, setEnteredAmount] = useState(props.enteredAmount);
    const [fromCurrency, setFromCurrency] = useState(props.fromCurrency);

    const selectedCurrency = (event) => {
        setFromCurrency(event.target.value);
        
    }

    const setAmount = (event) => {
        setEnteredAmount(event.target.value);
        
    };

    const checkResult = () => {
        props.checkResult(enteredAmount, fromCurrency);
    }


    return (
        <li>
            <Card className='currency-item'>
                <div className='currency-item__box'>
                    <h2> {fromCurrency} <span style={{color: "lightgreen"}}>to</span> { props.code } </h2>
                    <div className='currency-item__data'> 
                        <label htmlFor="amount">Amount</label>
                        <input id="amount" type="number"   value={enteredAmount} onChange={setAmount}></input>

                        <select value={fromCurrency} onChange={selectedCurrency}>
                            <option value=''> Amount Currency </option>
                            {props.conversionRates.map(conversionRate => 
                                <option key={Math.random().toString()} value={conversionRate.code}>
                                    {conversionRate.code}
                                </option>
                            )}
                        </select>
                        
                    </div>
                    <button onClick={checkResult} className="calculate"> Calculate </button>
                </div>
            </Card>
        </li>
    );
}

export default CurrencyItem;