import React from "react";

import Card from "../UI/Card";
import CurrenciesList from "./CurrenciesList";
import './Currencies.css';

const Currencies = (props) => {

    const checkResult = (enteredAmount, currency) => {
        props.checkResult(enteredAmount, currency);
    }

    return(
        <div>
            <Card className="currencies">
                <CurrenciesList  
                    enteredAmount={props.enteredAmount} 
                    fromCurrency={props.fromCurrency} 
                    checkResult={checkResult}  
                    toCurrency={props.toCurrency} 
                    calculatedValue={props.calculatedValue} 
                    currencies={props.currencies}
                ></CurrenciesList>
            </Card>
        </div>
    );

}

export default Currencies;