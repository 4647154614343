import React from 'react';
import './CurrenciesFilter.css';

const CurrenciesFilter = (props) => {

    const dropdownChangeHandler = (event) => {
        props.onChangeCurrency(event.target.value);
    }

  return (
    <div className='currencies-filter'>
      <div className='currencies-filter__control'>
        <label>Choose currency in which you want to convert amount : </label>
        <select value={props.toCurrency} onChange={dropdownChangeHandler}>
          <option value=''> Choose currency </option>
          {props.currencies.map(currency => 
              <option key={Math.random().toString()} value={currency.code}>
                  {currency.code}
              </option>
          )}
        </select>
      </div>
    </div>
  );
};

export default CurrenciesFilter;