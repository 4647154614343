import React from "react";

import CurrencyItem from "./CurrencyItem";
import "./CurrenciesList.css";

const CurrenciesList = (props) => {

    if(props.toCurrency === '' ){
        return <h2 className="currencies-list__fallback">You did not choose currency - Choose currency in which you want to convert amount</h2>;
    }

    const checkResult = (enteredAmount, currency) => {
        props.checkResult(enteredAmount, currency);
    }
    
    return (
        <div>
            <ul className="currencies-list">
                {
                    props.currencies.map((currency) => 
                        <CurrencyItem 
                            key={Math.random().toString()} 
                            code={currency.code}
                            conversionRates={currency.conversionRates} 
                            fromCurrency={props.fromCurrency}
                            checkResult={checkResult}
                            enteredAmount={props.enteredAmount}
                        ></CurrencyItem>
                    )
                }
            </ul>
            <div className="calculated_value_holder">
                <span className="amount">{props.calculatedValue}</span> <span className="code">{props.toCurrency}</span>
            </div>
        </div>
    );

}

export default CurrenciesList;